<template>
  <div v-if="data" class="el_1_d797791801ac098c93a15664a52cdd9a">
    <div class="el_2_d797791801ac098c93a15664a52cdd9a absolute top-5 right-6">
      <tg-btn color="success" :disabled="sending" @click="get_otp = true">
        Save
      </tg-btn>
      <tg-btn v-if="client.stage == 'UAT'" color="gray" :disabled="sending" @click="resync">
        Prod Sync
      </tg-btn>
    </div>

    <form v-if="data" autocomplete="off" class="pt-5" @submit.prevent>
      <div class="el_3_d797791801ac098c93a15664a52cdd9a pb-3 mb-3 border-b border-gray-200 border-solid">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          Data Config
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          The Table will be used for all CRUD operations and the View will be used to display page
          data
        </p>
      </div>

      <div class="el_4_d797791801ac098c93a15664a52cdd9a grid grid-cols-6 gap-x-4 gap-y-6 mt-6">
        <div class="el_5_d797791801ac098c93a15664a52cdd9a col-span-2">
          <label for="table" class="block text-sm font-medium text-gray-700"> Page Key </label>
          <div class="el_6_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="slug" type="text" name="slug" :value="data.slug" disabled
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>

        <div class="el_7_d797791801ac098c93a15664a52cdd9a col-span-2">
          <label for="table" class="block text-sm font-medium text-gray-700"> Table </label>
          <div class="el_8_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="table" v-model="data.data_table" type="text" name="table"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>

        <div class="el_9_d797791801ac098c93a15664a52cdd9a col-span-2">
          <label for="view" class="block text-sm font-medium text-gray-700"> View </label>
          <div class="el_10_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="view" v-model="data.data_view" type="text" name="view"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>
      </div>

      <div class="el_11_d797791801ac098c93a15664a52cdd9a grid grid-cols-12 gap-x-4 gap-y-6 mt-6">
        <div class="el_12_d797791801ac098c93a15664a52cdd9a col-span-3">
          <label for="table" class="block text-sm font-medium text-gray-700"> CRUD DB Key </label>
          <div class="el_13_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="crud_db_key" v-model="data.crud_db_key" type="text" name="crud_db_key"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>
        <div class="el_14_d797791801ac098c93a15664a52cdd9a col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Data Conditions
          </label>
          <div class="el_15_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="data_condition" v-model="data.data_condition" type="text" name="data_condition"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>
        <!-- <div class="el_16_d797791801ac098c93a15664a52cdd9a col-span-4">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Guided Tours Key
          </label>
          <div class="el_17_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="tour_key" v-model="data.pageconfig.tour_key" type="text" name="tour_key" class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div> -->
        <div class="el_16_d797791801ac098c93a15664a52cdd9a col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Drag Sortable Field
          </label>
          <div class="el_17_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="sortable" v-model="data.pageconfig.sortable" type="text" name="tour_key"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>
        <div class="el_16_d797791801ac098c93a15664a52cdd9a col-span-3">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Database Interaction Security
          </label>
          <SwitchGroup as="div" class="flex items-center mt-3"
            @click="data.pageconfig.require_2fa = !data.pageconfig.require_2fa">
            <Switch :value="data.pageconfig.require_2fa" :class="[
              data.pageconfig.require_2fa ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_24_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.require_2fa ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_25_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Require
                2FA</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>

      <div class="el_18_d797791801ac098c93a15664a52cdd9a grid grid-cols-6 gap-x-4 gap-y-6 mt-6">
        <div class="el_19_d797791801ac098c93a15664a52cdd9a col-span-6">
          <label for="view" class="block text-sm font-medium text-gray-700"> Description </label>
          <div class="el_20_d797791801ac098c93a15664a52cdd9a mt-1">
            <textarea id="description" v-model="data.description" type="text" name="description"
              class="block w-full h-32 rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm" />
            <TgOpenAi :content="data.description" @accept="data.description = $event" />
          </div>
        </div>
      </div>

      <div class="el_21_d797791801ac098c93a15664a52cdd9a pb-3 mt-10 mb-3 border-b border-gray-200 border-solid">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          Page Options
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          These options control all the form related configs for a data.
        </p>
      </div>

      <div class="el_22_d797791801ac098c93a15664a52cdd9a flex mb-2">
        <div class="el_23_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.allow_create = !data.pageconfig.allow_create">
            <Switch :value="data.pageconfig.allow_create" :class="[
              data.pageconfig.allow_create ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_24_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.allow_create ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_25_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Allow
                Create</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <div class="el_26_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.allow_update = !data.pageconfig.allow_update">
            <Switch :value="data.pageconfig.allow_update" :class="[
              data.pageconfig.allow_update ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_27_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.allow_update ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_28_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Allow
                Edit</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <div class="el_29_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.allow_delete = !data.pageconfig.allow_delete">
            <Switch :value="data.pageconfig.allow_delete" :class="[
              data.pageconfig.allow_delete ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_30_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.allow_delete ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_31_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Allow
                Delete</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <div class="el_32_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.allow_export = !data.pageconfig.allow_export">
            <Switch :value="data.pageconfig.allow_export" :class="[
              data.pageconfig.allow_export ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_33_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.allow_export ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_34_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Allow
                Export</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>
      <div class="el_22_d797791801ac098c93a15664a52cdd9a flex my-2">
        <div class="el_35_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.disable_groupby = !data.pageconfig.disable_groupby">
            <Switch :value="data.pageconfig.disable_groupby" :class="[
              data.pageconfig.disable_groupby ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_36_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.disable_groupby ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_37_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Disable
                SQL Group By</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <div class="el_38_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.skip_row_locking = !data.pageconfig.skip_row_locking">
            <Switch :value="data.pageconfig.skip_row_locking" :class="[
              data.pageconfig.skip_row_locking ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_39_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.skip_row_locking ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_40_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Disable
                Row Locking</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <div class="el_38_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.db_row_locking = !data.pageconfig.db_row_locking">
            <Switch :value="data.pageconfig.db_row_locking" :class="[
              data.pageconfig.db_row_locking ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_39_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.db_row_locking ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_40_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">DB Row
                Locking</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <div class="el_38_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.show_archive_btn = !data.pageconfig.show_archive_btn">
            <Switch :value="data.pageconfig.show_archive_btn" :class="[
              data.pageconfig.show_archive_btn ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_39_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.show_archive_btn ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_40_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Show
                Archive Btn</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>

      <div class="el_41_d797791801ac098c93a15664a52cdd9a grid grid-cols-6 gap-x-4 gap-y-6 mt-6">
        <div class="el_42_d797791801ac098c93a15664a52cdd9a col-span-1">
          <label for="view" class="block text-sm font-medium text-gray-700"> Page Size </label>
          <div class="el_43_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="size" v-model="data.pageconfig.size" type="text" name="size"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>
        <div class="el_44_d797791801ac098c93a15664a52cdd9a col-span-1">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Form Panel Size
          </label>
          <div class="el_45_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="size" v-model="data.pageconfig.form_panel_size" type="text" name="size"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>
        <div class="el_46_d797791801ac098c93a15664a52cdd9a col-span-1">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Grid Width Type
          </label>
          <div class="el_47_d797791801ac098c93a15664a52cdd9a mt-1">
            <select v-model="data.pageconfig.grid_width_type"
              class="block py-2 pr-10 pl-3 w-full text-sm rounded-md border-gray-200 focus:ring-primary-500 focus:border-primary-500 focus:outline-none">
              <option v-for="item in ['px', '%', 'span']" :key="item" :value="item">
                {{ item }}
              </option>
            </select>
          </div>
        </div>
        <div class="el_48_d797791801ac098c93a15664a52cdd9a col-span-1">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Cloudinay Folder
          </label>
          <div class="el_49_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="size" v-model="data.pageconfig.cloudinary" type="text" name="size"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>
        <div class="el_50_d797791801ac098c93a15664a52cdd9a col-span-1">
          <label for="view" class="block text-sm font-medium text-gray-700">
            Cloudinay Filename Field
          </label>
          <div class="el_51_d797791801ac098c93a15664a52cdd9a mt-1">
            <input id="size" v-model="data.pageconfig.cloudinary_filename" type="text" name="size"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
          </div>
        </div>
      </div>

      <div class="el_52_d797791801ac098c93a15664a52cdd9a pb-3 mt-10 mb-3 border-b border-gray-200 border-solid">
        <h3 class="text-lg font-medium leading-6 text-gray-900">
          Filter Options
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">
          These options control all the filter related configs for a data.
        </p>
      </div>

      <div class="el_53_d797791801ac098c93a15664a52cdd9a flex mb-2">
        <div class="el_54_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.auto_filters = !data.pageconfig.auto_filters">
            <Switch :value="data.pageconfig.auto_filters" :class="[
              data.pageconfig.auto_filters ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_55_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.auto_filters ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_56_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Auto
                Filter</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <div class="el_57_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.auto_grid = !data.pageconfig.auto_grid">
            <Switch :value="data.pageconfig.auto_grid" :class="[
              data.pageconfig.auto_grid ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_58_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.auto_grid ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_59_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Auto
                Grid</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <div class="el_60_d797791801ac098c93a15664a52cdd9a flex-1 mr-2">
          <SwitchGroup as="div" class="flex items-center"
            @click="data.pageconfig.must_filter_first = !data.pageconfig.must_filter_first">
            <Switch :value="data.pageconfig.must_filter_first" :class="[
              data.pageconfig.must_filter_first ? 'bg-primary-400' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
            ]">
              <span class="el_61_d797791801ac098c93a15664a52cdd9a" aria-hidden="true" :class="[
                data.pageconfig.must_filter_first ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
              ]" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="el_62_d797791801ac098c93a15664a52cdd9a text-sm font-medium text-gray-500 capitalize">Require
                Filter First</span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
      </div>

      <div class="el_52_d797791801ac098c93a15664a52cdd9a pb-3 mt-10 mb-3 p-3 flex flex-col space-y-3 bg-red-100">
        <h3 class="text-lg font-medium leading-6 text-red-500">
          Danger Zone
        </h3>
        <p class="text-gray-700">
          Confirm slug delete by typing the word 'delete' into the input below.
        </p>
        <input v-model="delete_confirm" placeholder="type delete " class="p-1 text-lg border-red-400 w-72">
        <button class="p-2 disabled:opacity-50 bg-red-400 text-white w-72" :disabled="delete_confirm != 'delete'"
          @click="delete_slug">
          Delete Page
        </button>
      </div>
    </form>
  </div>

  <Code2FA v-if="get_otp" :sending="sending" :error="error" @submit="save" @cancel="get_otp = false" />
</template>

<script>
import { STORE } from "@/store";
import TgBtn from "@/components/Common/TgBtn";
import Code2FA from "@/components/Common/Code2FA";
import { API } from "@/api";
import _has from "lodash/has";
import { PAGECONFIG } from "@/default";
import TgOpenAi from "@/components/Common/TgOpenAi";

import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

export default {
  name: "ConfigSettings",
  components: {
    Switch,
    SwitchGroup,
    SwitchLabel,
    TgOpenAi,
    TgBtn,
    Code2FA,
  },
  props: {
    slug: String,
    tab: Object,
  },
  emits: ["on:save", 'on:close'],
  data() {
    return {
      sending: false,
      data: null,
      error: null,
      get_otp: false,
      delete_confirm: null,
      client: this.$auth.client.value,
    };
  },

  async mounted() {
    let resp = await API.page({ slug: this.slug });

    if (!_has(resp.data, "data")) {
      resp.data.data = {
        slug: this.slug,
        pageconfig: PAGECONFIG,
      };
    }

    this.data = resp.data.data;
  },
  methods: {
    async delete_slug() {
      this.removing = true;
      try {
        await API.remove_slug(this.slug);
        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "Page removed!",
        });
        this.$emit("on:close");
      } catch (e) { }
      this.removing = false;
    },
    async resync() {
      this.sending = true;
      let payload = {
        worker: "db-admin-create-uat-table",
        label: `virgin_uat.dbo.${this.data.data_table}`,
        payload: JSON.stringify({
          tbl: this.data.data_table,
          proc: `DB_Sync_UpdateData_${this.client.key.toUpperCase()}`,
        }),
      };
      await API.insert_worker_job(payload);
      API.bump_worker(payload.worker);
      this.sending = false;
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Prod sync queued up!",
      });
    },
    async save(otp) {
      this.sending = true;
      this.error = null;
      try {
        await STORE.set(this.slug, this.data, otp);
        this.$notify({
          group: "toast",
          type: "success",
          title: "Success",
          text: "Page Saved!",
        });
        this.get_otp = false;
        this.sending = false;
        this.$emit("on:save");
      } catch (e) {
        this.sending = false;
        this.error = e.response.data.message;
      }
    },
  },
};
</script>

<style></style>
